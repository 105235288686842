<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <AddressElementSelect v-model="building_id"
                                  label="Будинок(-ки)" filled
                                  select_type="building" req
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text"
                          filled
                          label="Назва приладу обліку"
                          v-model="counter_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="counter_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-select type="text"
                      filled
                      :items="services"
                      label="Послуга"
                      v-model="service_id"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_id ? '' : 'req-star'"
                      color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-select type="text"
                      filled
                      :items="buildingCalcMethod"
                      label="Методика розрахунку"
                      v-model="calc_method"
                      hide-details
                      @change="afterCalcMethodChange"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="calc_method ? '' : 'req-star'"
                      color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <v-switch
                v-model="flat_counters_volume_minus"
                inset
                :disabled="flat_counters_volume_minus_disable"
                hide-details
                class="ml-2"
                color="success"
                :label="flat_counters_volume_minus ? 'Відняти об’єм кварт. показів' : 'Не віднімати об’єм кварт. показів'"
            />
          </v-col>
          <v-col cols="12" md="12" v-if="indicator_id_square_show">
            <v-select :items="indicators_select" hide-details filled label="Вид показника площі"
                      v-model="indicator_id_square"
                      required
                      :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="indicator_id_square ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12" md="12" v-if="indicator_id_person_show">
            <v-select :items="indicators_select"
                      hide-details
                      filled label="Вид показника проживаючих/зареєстрованих)"
                      v-model="indicator_id_person"
                      required
                      :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="indicator_id_person ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="6" md="6">
            <CounterModel :value="model_id" @autocompleteChange="modelChange" :building="true"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="model_id ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field type="text"
                          filled
                          label="Серійний номер"
                          v-model="serial_number"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="serial_number ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <date-component v-model="date_start" req label="Дата встановлення"/>
          </v-col>
          <v-col cols="6" md="6">
            <date-component v-model="date_end" label="Дата демонтажу"/>
          </v-col>
          <v-col cols="6" md="6">
            <v-switch
                v-model="zones_counter"
                inset
                hide-details
                class="ml-2"
                @change="changeZonezCounter"
                color="success"
                :label="zones_counter ? 'Облік зон лічильника' : 'Зонність не передбачена'"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field type="number"
                          min="0"
                          max="3"
                          filled
                          label="Кількість зон"
                          v-model.number="zone"
                          v-decimal
                          hide-details
                          :disabled="!zones_counter"
                          required
                          :rules="zones_counter ? [v => !!v || 'Це поле є обов’язковим'] : [() => true]"
                          :class="zones_counter ? zone ? '' : 'req-star' : ''"
                          color="grey"/>
          </v-col>
          <template v-if="!zone || zone === 1">
            <v-col :cols="isNew ? 12 : 6">
              <v-text-field type="text"
                            filled
                            label="Початковий показник"
                            v-decimal
                            v-model.number="start_meter_reading_first"
                            hide-details
                            required :rules="[v => +v >= 0 || 'Це поле є обов’язковим']"
                            :class="start_meter_reading_first >= 0 ? '' : 'req-star'"
                            :disabled="isNew ? false : !change_start_reading"
                            color="grey"/>
            </v-col>
            <v-col cols="6" v-if="!isNew">
              <v-switch v-model="change_start_reading" hide-details label="Змінити початковий?"
                        color="success" class="pt-0"/>
            </v-col>
          </template>
          <template v-if="zone === 2">
            <v-col cols="12" class="pt-2 pb-0">
              <span class="subtitle-1">Початкові показники</span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-1" v-if="!isNew">
              <v-switch v-model="change_start_reading"
                        label="Змінити початковий?"
                        hide-details
                        color="success"
                        class="pt-0 mt-0"
              />
            </v-col>
            <v-col cols="6" md="6" class="pt-1">
              <v-text-field type="text"
                            filled
                            label="Зона №1"
                            v-decimal
                            v-model.number="start_meter_reading_first"
                            hide-details
                            required :rules="[v => +v >= 0 || 'Це поле є обов’язковим']"
                            :class="start_meter_reading_first >= 0 ? '' : 'req-star'"
                            :disabled="isNew ? false : !change_start_reading"
                            color="grey"/>
            </v-col>
            <v-col cols="6" md="6" class="pt-1">
              <v-text-field type="text"
                            filled
                            label="Зона №2"
                            v-decimal
                            v-model.number="start_meter_reading_second"
                            hide-details
                            required :rules="[v => +v >= 0 || 'Це поле є обов’язковим']"
                            :class="start_meter_reading_second >= 0 ? '' : 'req-star'"
                            :disabled="isNew ? false : !change_start_reading"
                            color="grey"/>
            </v-col>
          </template>
          <template v-if="zone === 3">
            <v-col cols="12" class="pt-2 pb-0">
              <span class="subtitle-1">Початкові показники</span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-1" v-if="!isNew">
              <v-switch v-model="change_start_reading"
                        label="Змінити початковий?"
                        hide-details
                        color="success"
                        class="pt-0 mt-0"
              />
            </v-col>
            <v-col cols="12" md="4" class="pt-1">
              <v-text-field type="text"
                            filled
                            label="Зона №1"
                            v-decimal
                            v-model.number="start_meter_reading_first"
                            hide-details
                            required :rules="[v => +v >= 0 || 'Це поле є обов’язковим']"
                            :class="start_meter_reading_first >= 0 ? '' : 'req-star'"
                            :disabled="isNew ? false : !change_start_reading"
                            color="grey"/>
            </v-col>
            <v-col cols="12" md="4" class="pt-1">
              <v-text-field type="text"
                            filled
                            label="Зона №2"
                            v-decimal
                            v-model.number="start_meter_reading_second"
                            hide-details
                            required :rules="[v => +v >= 0 || 'Це поле є обов’язковим']"
                            :class="start_meter_reading_second >= 0 ? '' : 'req-star'"
                            :disabled="isNew ? false : !change_start_reading"
                            color="grey"/>
            </v-col>
            <v-col cols="12" md="4" class="pt-1">
              <v-text-field type="text"
                            filled
                            label="Зона №3"
                            v-decimal
                            v-model.number="start_meter_reading_third"
                            hide-details
                            required :rules="[v => +v >= 0 || 'Це поле є обов’язковим']"
                            :class="start_meter_reading_third >= 0 ? '' : 'req-star'"
                            :disabled="isNew ? false : !change_start_reading"
                            color="grey"/>
            </v-col>
          </template>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  CREATE_BUILDING_COUNTER, UPDATE_BUILDING_COUNTER, REMOVE_BUILDING_COUNTER
} from "@/store/actions/counter";
import {mapActions, mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {buildingCalcMthod} from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";
import {FETCH_FLAT_INDICATOR_TYPES} from "@/store/actions/flat_indicators";

const modalDeleteId = 'building_counter_modal_delete'

export default {
  name: "HWP_Modal_BuildingCounter",
  mixins: [ModalComponentMixin],
  components: {
    CounterModel: () => import("@/components/autocomplite/CounterModel"),
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  data() {
    return {
      building_id: this.item.building_id,
      counter_name: this.item.counter_name,
      calc_method: this.item.calc_method || null,
      zone: this.item.zone || 0,
      service_id: this.item.service_id || 0,
      start_meter_reading_first: this.item.start_meter_reading_first || 0,
      start_meter_reading_second: this.item.start_meter_reading_second || 0,
      start_meter_reading_third: this.item.start_meter_reading_third || 0,
      date_start: this.item.date_start || null,
      date_end: this.item.date_end || null,
      prev_verification: this.item.date_end || 0,
      next_verification: this.item.date_end || 0,
      serial_number: this.item.serial_number || "",
      model_id: this.item.model_id || 0,
      active: this.item.active === undefined ? true : this.item.active,
      zones_counter: !(this.zone === undefined || this.zone === 0),
      change_start_reading: false,
      buildingCalcMethod: buildingCalcMthod,
      indicator_id_square: this.item.indicator_id_square,
      indicator_id_person: this.item.indicator_id_person,
      flat_counters_volume_minus: this.item.flat_counters_volume_minus || false
    }
  },
  methods: {
    ...mapActions(
        {
          fetchIndicators: FETCH_FLAT_INDICATOR_TYPES
        }
    ),
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.counter_name = this.item.counter_name
      this.calc_method = this.item.calc_method || null
      this.building_id = this.item.building_id
      this.zone = this.item.zone || 0
      this.service_id = this.item.service_id || 0
      this.start_meter_reading_first = this.item.start_meter_reading_first || 0
      this.start_meter_reading_second = this.item.start_meter_reading_second || 0
      this.start_meter_reading_third = this.item.start_meter_reading_third || 0
      this.date_start = this.item.date_start || null
      this.date_end = this.item.date_end || null
      this.prev_verification = this.item.date_end || 0
      this.next_verification = this.item.date_end || 0
      this.serial_number = this.item.serial_number || ""
      this.model_id = this.item.model_id || 0
      this.zones_counter = !(this.item.zone === undefined || this.item.zone === 0)
      this.active = this.item.active === undefined ? true : this.item.active
      this.change_start_reading = false
      this.indicator_id_square = this.item.indicator_id_square
      this.indicator_id_person = this.item.indicator_id_person
      this.flat_counters_volume_minus = this.item.flat_counters_volume_minus || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      // this.$emit('closeModal')
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення лічильника',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Послуга, Модель, Серійний номер, Методика розрахунку, Дата встановлення - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        name: this.counter_name,
        building_id: this.building_id,
        service_id: this.service_id,
        calc_method: this.calc_method,
        zone: this.zone,
        start_meter_reading_first: this.start_meter_reading_first,
        start_meter_reading_second: this.start_meter_reading_second,
        start_meter_reading_third: this.start_meter_reading_third,
        date_start: this.date_start,
        date_end: this.date_end,
        prev_verification: this.prev_verification,
        next_verification: this.next_verification,
        serial_number: this.serial_number,
        model_id: this.model_id,
        active: this.active,
        change_start_reading: this.change_start_reading || false,
        indicator_id_square: this.indicator_id_square,
        indicator_id_person: this.indicator_id_person,
        flat_counters_volume_minus: this.flat_counters_volume_minus || false
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_BUILDING_COUNTER, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_BUILDING_COUNTER, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.building_id = payload.building_id
              this.counter_name = payload.counter_name
              this.calc_method = payload.calc_method || null
              this.zone = payload.zone || 0
              this.service_id = payload.service_id || 0
              this.start_meter_reading_first = payload.start_meter_reading_first || 0
              this.start_meter_reading_second = payload.start_meter_reading_second || 0
              this.start_meter_reading_third = payload.start_meter_reading_third || 0
              this.date_start = payload.date_start || null
              this.date_end = payload.date_end || null
              this.prev_verification = payload.date_end || 0
              this.next_verification = payload.date_end || 0
              this.serial_number = payload.serial_number || ""
              this.model_id = payload.model_id || 0
              this.zones_counter = !(payload.zone === undefined || payload.zone === 0)
              this.active = payload.active === undefined ? true : payload.active
              this.change_start_reading = false
              this.indicator_id_square = payload.indicator_id_square
              this.indicator_id_person = payload.indicator_id_person
              this.flat_counters_volume_minus = payload.flat_counters_volume_minus || false

              if (!this.indicators_select.length) {
                this.fetchIndicators()
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_BUILDING_COUNTER, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    modelChange(payload) {
      this.model_id = payload.value
    },
    changeZonezCounter() {
      if (this.zones_counter) {
        this.zone = 1
      } else {
        this.zone = 0
      }
    },
    afterCalcMethodChange() {
      this.$nextTick(() => {
        switch (this.calc_method) {
          case 'WATER_METHOD':
            this.flat_counters_volume_minus = true
            break
          case 'WATER_BY_ALL_METHOD':
            this.flat_counters_volume_minus = false
            break
          case 'WATER_BY_SQUARE':
            this.flat_counters_volume_minus = false
            break
          case 'WATER_PROPORTIONAL_COUNTER_VALUES':
            this.flat_counters_volume_minus = false
            break
          case 'ELECTRICITY_METHOD':
            this.flat_counters_volume_minus = true
            break
          case 'ELECTRICITY_BY_ALL_METHOD':
            this.flat_counters_volume_minus = false
            break
          case 'ELECTRICITY_BY_SQUARE':
            this.flat_counters_volume_minus = false
            break
          case 'ELECTRICITY_PROPORTIONAL_COUNTER_VALUES':
            this.flat_counters_volume_minus = false
            break
          case 'GAZ_METHOD':
            this.flat_counters_volume_minus = true
            break
          case 'GAZ_BY_ALL_METHOD':
            this.flat_counters_volume_minus = false
            break
          case 'GAZ_BY_SQUARE':
            this.flat_counters_volume_minus = false
            break
          case 'GAZ_PROPORTIONAL_COUNTER_VALUES':
            this.flat_counters_volume_minus = false
            break
          case 'HEATING_OLD_METHOD':
            return true
          case 'HEATING_NEW_METHOD':
            this.flat_counters_volume_minus = true
            break
          default:
            this.flat_counters_volume_minus = false
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithCounter',
      indicators_select: 'getFlatIndicatorTypes',
    }),
    flat_counters_volume_minus_disable() {
      switch (this.calc_method) {
        case 'WATER_METHOD':
          return true
        case 'WATER_BY_ALL_METHOD':
          return false
        case 'WATER_BY_SQUARE':
          return false
        case 'WATER_PROPORTIONAL_COUNTER_VALUES':
          return false
        case 'ELECTRICITY_METHOD':
          return true
        case 'ELECTRICITY_BY_ALL_METHOD':
          return false
        case 'ELECTRICITY_BY_SQUARE':
          return false
        case 'ELECTRICITY_PROPORTIONAL_COUNTER_VALUES':
          return false
        case 'GAZ_METHOD':
          return true
        case 'GAZ_BY_ALL_METHOD':
          return false
        case 'GAZ_BY_SQUARE':
          return false
        case 'GAZ_PROPORTIONAL_COUNTER_VALUES':
          return false
        case 'HEATING_OLD_METHOD':
          return true
        case 'HEATING_NEW_METHOD':
          return true
        default:
          return true
      }
    },
    indicator_id_square_show() {
      switch (this.calc_method) {
        case 'WATER_METHOD':
          return false
        case 'WATER_BY_ALL_METHOD':
          return false
        case 'WATER_BY_SQUARE':
          return true
        case 'WATER_PROPORTIONAL_COUNTER_VALUES':
          return false
        case 'ELECTRICITY_METHOD':
          return false
        case 'ELECTRICITY_BY_ALL_METHOD':
          return false
        case 'ELECTRICITY_BY_SQUARE':
          return true
        case 'ELECTRICITY_PROPORTIONAL_COUNTER_VALUES':
          return false
        case 'GAZ_METHOD':
          return false
        case 'GAZ_BY_ALL_METHOD':
          return false
        case 'GAZ_BY_SQUARE':
          return true
        case 'GAZ_PROPORTIONAL_COUNTER_VALUES':
          return false
        case 'HEATING_OLD_METHOD':
          return true
        case 'HEATING_NEW_METHOD':
          return true
        default:
          return false
      }
    },
    indicator_id_person_show() {
      switch (this.calc_method) {
        case 'WATER_METHOD':
          return true
        case 'WATER_BY_ALL_METHOD':
          return false
        case 'WATER_BY_SQUARE':
          return false
        case 'WATER_PROPORTIONAL_COUNTER_VALUES':
          return false
        case 'ELECTRICITY_METHOD':
          return true
        case 'ELECTRICITY_BY_ALL_METHOD':
          return false
        case 'ELECTRICITY_BY_SQUARE':
          return false
        case 'ELECTRICITY_PROPORTIONAL_COUNTER_VALUES':
          return false
        case 'GAZ_METHOD':
          return true
        case 'GAZ_BY_ALL_METHOD':
          return false
        case 'GAZ_BY_SQUARE':
          return false
        case 'GAZ_PROPORTIONAL_COUNTER_VALUES':
          return false
        case 'HEATING_OLD_METHOD':
          return false
        case 'HEATING_NEW_METHOD':
          return false
        default:
          return false
      }
    }
  }
}
</script>
